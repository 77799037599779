<template>
  <div>
    <v-form>
      <p class="mb-3">
        What are the two possible products for the aldol condensation (NOT double condensation)
        between benzaldehyde and ethylpropylketone? Select from the options listed below.
      </p>

      <v-simple-table>
        <v-row v-for="(part, partIndex) in optionsShuffled" :key="part">
          <v-col v-for="(option, index) in part" :key="option.value">
            <v-card outlined>
              <v-checkbox
                :id="'checkbox' + (index + partIndex)"
                v-model="inputs.studentAnswers"
                :value="option.value"
                class="pl-0 mb-n4 mt-0"
              />
              <label :for="'checkbox' + (index + partIndex)">
                <img :src="option.img" alt="" width="100%" class="pl-3" />
              </label>
            </v-card>
          </v-col>
        </v-row>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import {seededShuffle} from '@/courses/utils/seededShuffle';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'ChemUCI_51LCPL7_Q10',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {img: '/img/assignments/UCI_prelab7_Q10_optionA.png', value: 'a'},
        {img: '/img/assignments/UCI_prelab7_Q10_optionB.png', value: 'b'},
        {img: '/img/assignments/UCI_prelab7_Q10_optionC.png', value: 'c'},
        {img: '/img/assignments/UCI_prelab7_Q10_optionD.png', value: 'd'},
        {img: '/img/assignments/UCI_prelab7_Q10_optionE.png', value: 'e'},
        {img: '/img/assignments/UCI_prelab7_Q10_optionF.png', value: 'f'},
        {img: '/img/assignments/UCI_prelab7_Q10_optionG.png', value: 'g'},
        {img: '/img/assignments/UCI_prelab7_Q10_optionH.png', value: 'h'},
        {img: '/img/assignments/UCI_prelab7_Q10_optionI.png', value: 'i'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      let shuffledOptions = seededShuffle(this.options, this.seed);
      return {
        part1: [shuffledOptions[0], shuffledOptions[1], shuffledOptions[2]],
        part2: [shuffledOptions[3], shuffledOptions[4], shuffledOptions[5]],
        part3: [shuffledOptions[6], shuffledOptions[7], shuffledOptions[8]],
      };
    },
  },
};
</script>
